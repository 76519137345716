<template>
  <div>
    <!-- Filter -->
    <b-button
      class="btn-filter-appoitment btn-icon rounded-circle"
      variant="gradient-warning"
      @click="isActiveFilter = true"
    >
      <feather-icon icon="FilterIcon" size="25" />
    </b-button>
    <b-sidebar
      sidebar-class="sidebar-lg"
      bg-variant="white"
      v-model="isActiveFilter"
      right
      backdrop
      shadow
      no-header
    >
      <template #default="{ hide }" v-if="isActiveFilter">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Filtros</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <b-form class="px-1 mt-1">
          <b-row>
            <b-col cols="12">
              <b-form-group label="ID de Questão Sugerida">
                <b-form-input
                  autocomplete="off"
                  v-model="questionID"
                  type="number"
                  @keyup.enter="filter"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="ID do Agendamento">
                <b-form-input
                  autocomplete="off"
                  v-model="appointmentID"
                  type="number"
                  @keyup.enter="filter"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Nome do Vídeo">
                <b-form-input
                  autocomplete="off"
                  v-model="videoName"
                  @keyup.enter="filter"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Professor">
                <v-select
                  v-model="teacherSelected"
                  :options="teachers"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchTeacher(res);
                    }
                  "
                  :loading="teacherLoading"
                >
                  <template v-slot:option="option">
                    <h6 class="text-capitalize m-0">{{ option.label }}</h6>
                    <small v-if="option.email">
                      {{ option.email }}
                    </small>
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-group label="Instituição">
                <v-select
                  v-model="institutionSelected"
                  :options="institutions"
                  :clearable="true"
                  autocomplete="off"
                  @search="
                    (res) => {
                      searchInstitute(res);
                    }
                  "
                  :loading="instituteLoading"
                >
                  <template v-slot:option="option">
                    <h6 class="text-capitalize m-0">{{ option.label }}</h6>
                    <small v-if="option.email">
                      {{ option.email }}
                    </small>
                  </template>
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      Pesquisando... <em>{{ search }}</em>
                    </template>
                    <em style="opacity: 0.5" v-else>Digite o Nome</em>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Instituição">
                <b-form-checkbox
                  v-model="hasInstitution"
                  class="custom-control-success"
                  style="margin-bottom: 8px; margin-top: 10px"
                >
                  Não vazio
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Data de Cadastro">
                <b-form-datepicker
                  id="datepicker-1"
                  v-model="registrationDate"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :max="today"
                  locale="pt-br"
                  placeholder=""
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Sugestão">
                <b-form-checkbox
                  v-model="onlySuggestion"
                  class="custom-control-success"
                  style="margin-bottom: 8px; margin-top: 10px"
                >
                  Apenas com Sugestão
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col class="mt-2" cols="9">
              <b-button @click="filter" variant="gradient-info" block>
                FILTRAR
              </b-button>
            </b-col>
            <b-col class="mt-2" cols="3">
              <b-button @click="clearFilter" variant="gradient-primary" block>
                Limpar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
    <b-card>
      <b-overlay
        :variant="skin"
        :show="isloading"
        spinner-variant="primary"
        spinner-type="grow"
      >
        <Table
          :list="!isloading ? list[currentePage].itens : []"
          @rowClick="onClickSelected"
          :fields="fields"
          border="full"
          responsive
        >
          <template #appointment_id="data">
            {{ data.item.appointment_id || "--" }}
          </template>

          <template #teacher="data">
            <div
              v-if="mountTeacherList(data.item.mediaSubjectVideo).length > 0"
            >
              <b-badge
                v-for="(prof, id) in mountTeacherList(
                  data.item.mediaSubjectVideo
                )"
                :key="id"
                class="d-block"
                variant="light-info"
              >
                {{ prof }}
              </b-badge>
            </div>
            <div v-else class="text-center">--</div>
          </template>

          <template #institution="data">
            <div
              v-if="
                mountInstitutionList(data.item.mediaSubjectVideo).length > 0
              "
            >
              <b-badge
                v-for="(inst, id) in mountInstitutionList(
                  data.item.mediaSubjectVideo
                )"
                :key="id"
                class="d-block"
                variant="light-success"
              >
                {{ inst }}
              </b-badge>
            </div>
            <div v-else class="text-center">--</div>
          </template>

          <template #processed="data" style="width: 1px !important">
            <div class="text-nowrap notify-status">
              <b-avatar
                :variant="data.item.processed ? 'success' : 'warning'"
                :id="`scheduled-description-${data.item.media_id}`"
                size="25"
              >
                <feather-icon
                  size="16"
                  :icon="data.item.processed ? 'CheckIcon' : 'AlertCircleIcon'"
                />
              </b-avatar>
            </div>
          </template>
          <template #actions="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`edit-event-row-${data.item.media_id}`"
                icon="TrelloIcon"
                size="16"
                class="mx-1"
                @click="onClickSave(data.item)"
              />
            </div>
          </template>
        </Table>
      </b-overlay>
      <b-pagination
        class="mt-1"
        v-model="currentePage"
        @change="getLoadMore"
        :total-rows="rows"
        v-if="rows > 20"
        first-number
        last-number
        align="center"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Table from "@/components/Table.vue";
import _teachersService from "@/services/teachers-service";
import _institutionsService from "@/services/audiovisual/institutions-issues";
import {
  VBToggle,
  BFormCheckbox,
  BButton,
  BSidebar,
  BFormDatepicker,
  BBadge,
} from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import _service from "@/services/upload-service";
import uptos3_video from "./uptos3-video";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  props: {
    company: {
      type: Number,
      required: true,
    },
  },
  components: {
    "uptos3-video-form": uptos3_video,
    BFormCheckbox,
    Table,
    BButton,
    BSidebar,
    BFormDatepicker,
    BBadge,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  computed: {
    ...mapState({
      currentQuestionID: (state) => state.linksFilter.questionID,
      currentAppointmentID: (state) => state.linksFilter.appointmentID,
      currentVideoName: (state) => state.linksFilter.videoName,
      currentTeacherSelected: (state) => state.linksFilter.teacherSelected,
      currentInstitutionSelected: (state) =>
        state.linksFilter.institutionSelected,
      currentRegistrationDate: (state) => state.linksFilter.registrationDate,
      currentOnlySuggestion: (state) => state.linksFilter.onlySuggestion,
      currentHasInstitution: (state) => state.linksFilter.hasInstitution,
    }),
    questionID: {
      get() {
        return this.currentQuestionID;
      },
      set(value) {
        this.UPDATE_QUESTION_ID(value);
      },
    },
    appointmentID: {
      get() {
        return this.currentAppointmentID;
      },
      set(value) {
        this.UPDATE_APPOINTMENT_ID(value);
      },
    },
    videoName: {
      get() {
        return this.currentVideoName;
      },
      set(value) {
        this.UPDATE_VIDEO_NAME(value);
      },
    },
    teacherSelected: {
      get() {
        return this.currentTeacherSelected;
      },
      set(value) {
        this.UPDATE_SELECTED_TEACHER(value);
      },
    },
    institutionSelected: {
      get() {
        return this.currentInstitutionSelected;
      },
      set(value) {
        this.UPDATE_SELECTED_INSTITUTION(value);
      },
    },
    registrationDate: {
      get() {
        return this.currentRegistrationDate;
      },
      set(value) {
        this.UPDATE_REGISTRATION_DATE(value);
      },
    },
    onlySuggestion: {
      get() {
        return this.currentOnlySuggestion;
      },
      set(value) {
        this.UPDATE_ONLY_SUGGESTION();
      },
    },
    hasInstitution: {
      get() {
        return this.currentHasInstitution;
      },
      set(value) {
        this.UPDATE_HAS_INSTITUTION();
      },
    },
  },
  data() {
    return {
      isloading: false,
      currentePage: 1,
      search: "",
      size: 12,
      rows: 20,
      fields: [
        { key: "appointment_id", label: "ID do Agendamento" },
        { key: "video_name", label: "Nome Vídeo" },
        { key: "teacher", label: "Professor" },
        { key: "institution", label: "Instituição" },
        { key: "created_desc", label: "Criado em" },
        { key: "processed", label: "Sugestão Disponível" },
      ],
      list: [
        {
          page: 0,
          itens: [],
        },
      ],
      isSave: false,
      dto: null,
      date_created: null,
      only_suggestions: false,
      isActiveFilter: false,
      today: new Date(),
      teacherLoading: false,
      teacherPromisse: null,
      teachers: [],
      institutePromisse: null,
      instituteLoading: false,
      institutions: [],
    };
  },
  created() {
    this.getRecords(this.currentePage);
  },
  methods: {
    ...mapMutations({
      UPDATE_QUESTION_ID: "linksFilter/UPDATE_QUESTION_ID",
      UPDATE_APPOINTMENT_ID: "linksFilter/UPDATE_APPOINTMENT_ID",
      UPDATE_VIDEO_NAME: "linksFilter/UPDATE_VIDEO_NAME",
      UPDATE_SELECTED_TEACHER: "linksFilter/UPDATE_SELECTED_TEACHER",
      UPDATE_SELECTED_INSTITUTION: "linksFilter/UPDATE_SELECTED_INSTITUTION",
      UPDATE_REGISTRATION_DATE: "linksFilter/UPDATE_REGISTRATION_DATE",
      UPDATE_ONLY_SUGGESTION: "linksFilter/UPDATE_ONLY_SUGGESTION",
      UPDATE_HAS_INSTITUTION: "linksFilter/UPDATE_HAS_INSTITUTION",
    }),
    getRecords(_page) {
      this.isloading = true;
      _service
        .show(
          _page,
          this.company,
          this.questionID,
          this.appointmentID,
          this.videoName,
          this.teacherSelected ? this.teacherSelected.value : null,
          this.institutionSelected ? this.institutionSelected.value : null,
          this.registrationDate,
          this.onlySuggestion,
          this.hasInstitution
        )
        .then((res) => {
          if (res.content) {
            this.list.push({ page: _page, itens: res.content });
            if (res.content.length > 0) {
              this.rows += this.$utils.pagination(res.content, this.size);
              this.currentePage = _page;
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    getLoadMore(_page) {
      if (!this.list.some((s) => s.page === _page)) {
        this.getRecords(_page);
      }
    },
    filter() {
      this.currentePage = 1;
      this.rows = 20;
      this.list = [{ page: 0, itens: [] }];
      this.getRecords(this.currentePage);
      this.isActiveFilter = false;
    },
    clearFilter() {
      this.questionID = null;
      this.appointmentID = null;
      this.videoName = null;
      this.teacherSelected = null;
      this.institutionSelected = null;
      this.registrationDate = null;
      if (this.onlySuggestion) {
        this.UPDATE_ONLY_SUGGESTION();
      }
      if (this.hasInstitution) {
        this.UPDATE_HAS_INSTITUTION();
      }

      this.filter();
    },
    onClickSelected(record, _) {
      this.$router.push({
        path: `/uptos3/questions/${record.media_id}`,
      });
    },
    searchTeacher(_search) {
      if (_search && _search.length >= 3) {
        clearTimeout(this.teacherPromisse);
        this.teacherLoading = true;
        this.teacherPromisse = setTimeout(() => {
          _teachersService
            .autoCompleteAll(_search)
            .then((res) => {
              if (res.content && res.content.length > 0) {
                this.teachers = [];
                res.content.forEach((element) => {
                  this.teachers.push({
                    value: element.id,
                    label: element.name,
                  });
                });
              }
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.teacherLoading = false));
        }, 1500);
      }
    },
    searchInstitute(_search) {
      if (_search && _search.length >= 3) {
        clearTimeout(this.institutePromisse);
        this.instituteLoading = true;
        this.institutePromisse = setTimeout(() => {
          _institutionsService
            .find(_search)
            .then((res) => {
              if (res.content && res.content.length > 0) {
                this.institutions = [];
                res.content.forEach((element) => {
                  this.institutions.push({
                    value: element.id,
                    label: element.name,
                  });
                });
              }
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.instituteLoading = false));
        }, 1500);
      }
    },
    mountTeacherList(list) {
      if (list && Array.isArray(list) && list.length > 0) {
        return [...new Set(list.map((m) => m.teacher.name))];
      } else {
        return [];
      }
    },
    mountInstitutionList(list) {
      if (list && Array.isArray(list) && list.length > 0) {
        if (list.some((s) => s.institutionsIssues)) {
          var filtered = list.filter((f) => f.institutionsIssues);
          return [...new Set(filtered.map((m) => m.institutionsIssues.name))];
        }
      }
      return [];
    },
  },
};
</script>

<style scoped>
.btn-filter-appoitment {
  position: fixed;
  bottom: 5%;
  right: 45px;
  z-index: 99;
}
</style>